<template>
  <div>
    <div class="login-page d-flex flex-column shadow">
      <div class="card-title mb-0 me-1">
          <h5 class="mb-0 text-lorem">Dashboard</h5>
          <p class="mb-0 text-size">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
      </div>
      <hr>
      <div class="mt-2">
        <div class="filter-input position-relative d-flex flex-row gap-4 pb-3">
          <div class="position-relative d-flex flex-row gap-4">
            <div>
              <input type="text" v-model="filterByName" @keydown.enter="filterData" placeholder="Filter By Name"
                class="InputX" />
              <span class="clear-icon pointer btn position-absolute" @click="clearFilter" v-show="filterByName"
                style="left: 162px"><i class="fa-solid fa-xmark"></i></span>
            </div>

            <div>
              <input type="text" v-model="filterByMobile" @keydown.enter="filterData"
                placeholder="Filter By Mobile Number" class="InputX" />
              <span class="clear-icon pointer btn position-absolute" @click="clearFilter" v-show="filterByMobile"
                style="left: 390px"><i class="fa-solid fa-xmark"></i></span>
            </div>
          </div>
          <div>
            <div>
              <select v-model="sortStatus"
                style="padding: 6.3px;border: 1px solid rgb(201, 196, 196);border-radius: 7px;font-size: 12px; background-color: #fff; color: #3c3c3c;">
                <option value="">Sort By Status</option>
                <option value="Auditor_Approved">Auditor Approved</option>
                <option value="VKYC_Ongoing">VKYC Ongoing</option>
                <option value="VKYC_Completed">VKYC Completed</option>
                <option value="Additional Details Done">Additional Details Done</option>
              </select>
            </div>
          </div>
        </div>
        <table style="font-size: 14px;">
          <tr>
            <th>Name</th>

            <th>Cards Details</th>

            <th>Status</th>
            <th>Video Url</th>
            <!-- <th>Link</th> -->
          </tr>
          <tr v-for="(i, index) in paginatedData" :key="index">
            <td>
              <div class="add-padding">
                <div class="text-items">{{ i.firstName }} {{ i.lastName }}</div>
                <div class="d-flex flex-row gap-2 align-items-center mt-1" style="
    color: gray;
"><i class="fas fa-phone mr-1"></i> {{ i.mobileNo }}</div>
              </div>


            </td>
            <!-- <td>{{ i.lastName }}</td> -->
            <!-- <td><i class="fas fa-phone mr-1"></i> {{ i.mobileNo }}</td> -->


            <td>
              <div class="">
                <div class="d-flex flex-row gap-3 align-items-center text-items"><i class="fas fa-id-card"></i> {{
                  maskAdhar(i.adharCardNumber) }}</div>
                <div class="d-flex flex-row gap-3 align-items-center text-items mt-1"><i class="far fa-id-card"></i> {{
                  maskPan(i.panCardNumber) }}</div>
              </div>
            </td>


            <!-- <td>{{ maskPan(i.panCardNumber) }}</td> -->
            <td v-if="i.status === 'VKYC_Ongoing'">
              <button class="small-outline-button">In Progress</button>
            </td>

            <td>
              <a :href="i.video_url" target="_blank">Link</a>
            </td>


            <td>
              <div class="d-flex flex-row gap-3">
                <div>
                  <a class="text-white fw-bold " @click="clickDocument(i)">
                    <i class="fa fa-file" aria-hidden="true"></i>
                  </a>
                </div>
                <div>
                  <a :href="i.link">
                    <i class="fas fa-link"></i>
                  </a>
                </div>

              </div>

            </td>

            <!-- <td class="text-center">
                          <a :href="i.link">
    <i class="fas fa-link"></i> 
  </a>
</td> -->
          </tr>
        </table>
      </div>

      <div class="loader-container" v-if="loader">
        <div class="loader"></div>
      </div>

      <div class="pt-3">
        <nav aria-label="Page navigation example" class="m-0">
          <ul class="pagination justify-content-center m-0">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click.prevent="previousSet">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" v-for="page in displayedPages" :key="page" :class="{ active: page === currentPage }">
              <a class="page-link" href="#" @click.prevent="goToPage(page)">{{
                page
              }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click.prevent="nextSet">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="popup-Container" v-if="popUpWindow">
      <div class="pop_up">
        <div class="d-flex justify-content-between">
          <div></div>
          <div class="forcross d-flex justify-content-center align-items-center" @click="closePopUp">
            X
          </div>
        </div>
        <div>
          <div class="d-flex flex-wrap gap-3">
            <div class="d-flex flex-column card-div">
              <small>Aadhar</small>
              <i class="fas fa-file my-2" style="font-size: 40px; color: lightgray"></i>

              <i class="fa-solid fa-download iconColor" style="color: green; font-size: 25px" @click="forAadhar"></i>
            </div>
            <div class="d-flex flex-column card-div">
              <small>Pan</small>
              <i class="fas fa-file my-2" style="font-size: 40px; color: lightgray"></i>
              <i class="fa-solid fa-download iconColor" style="color: green; font-size: 25px" @click="forPan"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue-good-table/dist/vue-good-table.css";

export default {
  name: "dashboardTable",

  created() {
    this.getAllCases();
  },

  data() {
    return {
      pageTitle: 'Dashboard Table',
      popUpWindow: false,
      mobileNo: null,
      adharCardNumber: null,
      panCardNumber: null,
      firstName: null,
      video_url: null,
      lastName: null,
      getApiData: "",
      currentPage: 1,
      maxPagesShow: 3,
      perPages: 6,
      totalPages: null,
      pageOfItem: [],
      adharImage: null,
      panImage: null,
      filterByName: "",
      filterByMobile: "",
      sortStatus: "",
      startIndex: null,
      endIndex: null,
      filtered: {},
      panImageUrl: null,

    };
  },

  mounted() {
    document.title = this.pageTitle;
  },

  computed: {
    filteredData() {
      let filtered = this.getApiData.slice();
      if (this.filterByName) {
        filtered = filtered.filter(
          (item) =>
            item.firstName.toLowerCase().includes(this.filterByName.toLowerCase()) ||
            item.lastName.toLowerCase().includes(this.filterByName.toLowerCase())
        );
      }
      if (this.filterByMobile) {
        filtered = filtered.filter((item) =>
          item.mobileNo.toLowerCase().includes(this.filterByMobile.toLowerCase())
        );
      }
      if (this.sortStatus) {
        filtered = filtered.filter((item) => item.status === this.sortStatus);
      }
      return filtered;
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.perPages;
      const endIndex = Math.min(startIndex + this.perPages, this.filteredData.length);
      return this.filteredData.slice(startIndex, endIndex);
    },
    displayedPages() {
      const pages = [];
      const startPage = Math.max(1, this.currentPage - 2);
      const endPage = Math.min(startPage + 4, this.totalPages);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    },
  },




  methods: {
    clearFilter() {
      this.filterByName = ""; // Clear the name filter
      this.filterByMobile = ""; // Clear the mobile number filter
      // this.getAllCases(); // Fetch all data
    },

    getStatusOrder(status) {
      // Define the order of status values for sorting
      const statusOrder = {
        Auditor_Approved: 1,
        VKYC_Ongoing: 2,
        VKYC_Completed: 3,


        // Add more status values if needed
      };
      return statusOrder[status] || 0; // Return 0 if status not found in the order list
    },

    filterData() {
      // Fetch filtered data
      this.getAllCases();
    },
    async forAadhar() {
      try {
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const imgUrl = `${apiUrl}${this.adharImage}`;
        const link = document.createElement("a");
        link.href = imgUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    },
    async forPan() {
      try {
        debugger
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const imgUrl = `${apiUrl}${this.panImageUrl}`;
        const link = document.createElement("a");
        link.href = imgUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    },
    closePopUp() {
      this.popUpWindow = false;
    },
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //     this.getAllCases();
    //   }
    // },
    // goToPage(page) {
    //   this.currentPage = page;
    //   this.getAllCases();
    // },
    // previousPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //     this.getAllCases();
    //   }
    // },
    clickDownload() {
      debugger
      const content = this.convertToXlsx(this.getApiData);
      const blob = new Blob([content], { type: "text/xlsx;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = "download.xlsx";
      link.click();
    },
    convertToXlsx(data) {
      const header = Object.keys(data[0]);
      const row = data.map((obj) => header.map((header) => obj[header]));
      const headerRow = header.join(",");
      const csvRow = [headerRow, ...row.map((row) => row.join(","))];
      return csvRow.join("\n");
    },
    clickDocument(data) {
      this.popUpWindow = true;
      (this.adharImage = data.adharImageUrl),
        (this.panImage = data.panImageUrl);

      console.log(data);
    },
    async getAllCases() {
      try {
        this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/case/fetchAllCaseData`);
        console.log(response.data.data);
        // let popUpData= response.data.data.map((res)=>({
        //   adharImageUrl:res.adharImageUrl,
        //   panImageUrl:res.panImageUrl
        // }));

        this.getApiData = response.data.data.map((res) => ({
          mobileNo: res.mobileNo,
          adharCardNumber: res.adharCardNumber,
          panCardNumber: res.panCardNumber,
          link: res.link,
          firstName: res?.personalDetails?.name || "",
          lastName: res?.personalDetails?.lastname || "",
          adharImageUrl: res.adharImageUrl,
          panImageUrl: res.panImageUrl,
          status: res?.status || "",
          video_url: res?.video_url,
        }));
        console.log(this.getApiData);
        this.totalPages = Math.ceil(this.getApiData.length / this.perPages);
        // const startIndex = (this.currentPage - 1) * this.perPages;
        // const endIndex = startIndex + this.perPages;
        // this.getApiData = this.getApiData.slice(startIndex, endIndex);
        this.loader = false;
      } catch (error) {
        this.loader = false;
        console.error("Error during API call:", error);
      }
    },

    //   async getAllCases() {
    // try {
    //   this.loader = true;
    //   const scriptElement = document.querySelector("script[api-url]");
    //   const apiUrl = scriptElement.getAttribute("api-url");
    //   const response = await axios.get(`${apiUrl}/case/fetchAllCaseData`);
    //   this.getApiData = response.data.data;

    // // Calculate pagination values
    // this.totalPages = Math.ceil(allData.length / this.perPages);
    // this.startIndex = (this.currentPage - 1) * this.perPages;
    // this.endIndex = this.startIndex + this.perPages;

    // // Slice the data for current page without modifying the original data
    // this.paginatedData = allData.slice(this.startIndex, this.endIndex);

    //     this.loader = false;
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.getAllCases();
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAllCases();
      }
    },

    goToPage(page) {
      this.currentPage = page;
      this.getAllCases();
    },

    nextSet() {
      // Increment current page by 3 if it doesn't exceed the total pages
      if (this.currentPage + 3 <= this.totalPages) {
        this.currentPage += 3;
        this.getAllCases();
      }
    },

    previousSet() {
      // Decrement current page by 3 if it doesn't go below 1
      if (this.currentPage - 3 >= 1) {
        this.currentPage -= 3;
        this.getAllCases();
      }
    },

    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //   }
    // },

    // previousPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },
    maskPan(panNumber) {
      if (!panNumber) return "";
      const words = panNumber.split(" ");
      const visibleChars = 5;
      let maskedWords = [];
      for (let i = 0; i < words.length - 1; i++) {
        const maskedPart = "X".repeat(words[i].length);
        maskedWords.push(maskedPart);
      }
      const lastWord = words[words.length - 1];
      const visiblePartLastWord = lastWord.slice(
        0,
        Math.min(visibleChars, lastWord.length - 1)
      );
      const lastVisibleChar = lastWord[lastWord.length - 1]; // Get the last character of the last word
      const maskedPartLastWord = "X".repeat(
        Math.max(0, lastWord.length - visibleChars - 1)
      ); // We subtract 1 here to keep the last character visible
      maskedWords.push(
        visiblePartLastWord + maskedPartLastWord + lastVisibleChar
      );
      return maskedWords.join(" ");
    },

    maskAdhar(adharCardNumber) {
      if (!adharCardNumber) return "";

      const start = adharCardNumber.slice(0, 4);
      const end = adharCardNumber.slice(-4);

      const maskedMiddle = "X".repeat(adharCardNumber.length - 10);

      return `${start} ${maskedMiddle} ${end}`;
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pagination-div {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.form-section {
  position: relative;
  /* padding: 25px 20px; */
  background-color: rgb(255, 255, 255);
  /* min-height: 100vh; */
}

.forcross {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: lightgrey;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3);
  color: white;
  cursor: pointer;
}

.login-page {
  background-color: #fff;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 90vh; */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-Container {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 110;
}

.pop_up {
  padding: 10px;
  position: absolute;
  width: 50%;
  height: 40%;
  background-color: white;
  z-index: 110;
}

.card-div {
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: aliceblue;
  border: 1px solid lightgray;
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-primary {
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  border-radius: 5px;
  color: #f3f3f3;
}

@media (max-width: 768px) {
  .form-section {
    width: 100%;
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 1rem;
  }
}

.InputX {
  height: 30px;
  padding: 4px;
  border-radius: 7px;
  border: 1px solid #d9d1d1;
  color: #3c3c3c;
  /* font-size: 14px; */
  font-size: 12px;
  padding-left: 10px;
  /* font-size: 14px; */
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: rgb(34, 35, 37);
  border-color: rgb(34, 35, 37);
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: rgb(34, 35, 37);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.fa-phone:before {
  content: "\f095";
  color: #00000066;
}

.justify-nav {
  justify-content: space-evenly;
}

.shadow {
  background-clip: padding-box;
  box-shadow: none !important;
}
.fw-bold {
  color: rgb(34, 35, 37) !important;
}
.fa-link {
  color: rgb(34, 35, 37) !important;
}
th {
  color: rgb(34, 35, 37);
  font-size: 16px;
  font-weight: 500;
  font-family: Macan, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-items {
  color: #6f6b7d;
  text-align: left;
  font-size: .90rem;
  font-family: Public Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.text-size {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #a5a3ae !important;
}

.text-lorem {
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 36px !important;
  color: rgb(93, 89, 108) !important;
}

.small-outline-button {
  background-color: transparent;
    color: #28a745;
    border: 2px solid #28a745;
    border-radius: 20px;
    padding: 1px 6px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.small-outline-button:hover {
  background-color: #28a745;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}

.small-outline-button:active {
  transform: scale(0.98);
  box-shadow: none;
}

.add-padding {
  padding-left: 7px;
}

</style>
