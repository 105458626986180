<template>
  <section class="form-section d-flex flex-column gap-3">
    <div class="" :style="{ display: flexDivDisplay }">
      <div class=" d-flex justify-content-center align-items-center">
        <!-- <div class="captureArea" ref="captureArea"></div> -->
        <!-- <div style="position: absolute">
          <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc" cameraHeight="500" enableVideo="false"
            v-on:share-started="shareStarted" class="w-half" v-on:share-stopped="leftRoom" v-on:left-room="leftRoom"
            v-on:joined-room="joinedRoom" />
        </div> -->
      </div>
      <div>

        <div>
          <vue-webrtc id="call-canvas" :roomId="roomId" ref="webrtc" cameraWidth="689" cameraHeight="370"
            v-on:share-started="shareStarted" class="w-full" v-on:share-stopped="leftRoom" v-on:left-room="leftRoom"
            v-on:joined-room="joinedRoom" width="100%" style="object-fit: cover;width: 770px;" />
        </div>
        <!-- <div v-if="step1">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Light check</span>
              <span style="color: red">{{ lightWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(2)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step2">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Audio check</span>
              <span style="color: red">{{ audioWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(3)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step3">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Video check</span>
              <span style="color: red">{{ videoWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(4)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step4">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Background check</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(5)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step5">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Lat long check</span>
            </div>
            <button class="av-test-btn d-flex" @click="updateCaseDetails()">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div> -->
      </div>


      <div class="i mt-2">
        <!-- <div class="i-h">RE information</div> -->
        <!-- <hr /> 
        <div class="i-b d-flex justify-content-between">
          <div class="i-b-l">
            <div class="i-name"><strong>Name :</strong>
              {{ personalDetails.name }} {{ personalDetails.lastname }}
            </div>
            <div class="i-id"> <strong>Pincode :</strong>{{permanentAddress.pincode }}</div>
          </div>
          <div class="i-b-r"><strong>Address :</strong>
            {{ permanentAddress.addressLine1 }} , {{ permanentAddress.city }}
          </div>
          <canvas
            height="300"
            width="300"
            ref="canvasElement"
            v-show="show"
          ></canvas>
        </div> -->

        <div class="chatIcon">
          <i @click="toggleChatbox()" class="fas fa-envelope fa-message"
            style="font-size: 40px; color: aliceblue; cursor: pointer"></i>
        </div>
        <div v-if="enableChat" @event="handleEvent" class="popup">
          <Chat />
        </div>
        <div class="loader-container" v-if="loader">
          <div class="loader"></div>
        </div>
      </div>
    </div>
    <!-- <div class="login-header p-2 d-flex justify-content-between recordComp flex-column"> -->
    <!-- <div>  
          <div class="i-b d-flex justify-content-between">
          <div class="i-b-l">
            <div class="i-name"><strong>Name :</strong>
              {{ personalDetails.name }} {{ personalDetails.lastname }}
            </div>
            <div class="i-id"> <strong>Pincode :</strong>{{permanentAddress.pincode }}</div>
          </div>
          <div class="i-b-r"><strong>Address :</strong>
            {{ permanentAddress.addressLine1 }} , {{ permanentAddress.city }}
          </div>
          <canvas
            height="300"
            width="300"
            ref="canvasElement"
            v-show="show"
          ></canvas>
        </div> </div> -->
    <!-- <div class="d-flex flex-row justify-content-between">    
            <div class="r-live d-flex">
            <div class="r-icon text-danger">
              <span class="material-symbols-outlined">
                radio_button_checked
              </span>
            </div>
            <div class="r-live-h fw-bold mx-2">Recording live</div>
          </div>
        <div v-if="step1">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Light check</span>
              <span style="color: red">{{ lightWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(2)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step2">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Audio check</span>
              <span style="color: red">{{ audioWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(3)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step3">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Video check</span>
              <span style="color: red">{{ videoWarning }}</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(4)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step4">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Background check</span>
            </div>
            <button class="av-test-btn d-flex" @click="navigateToStep(5)">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
        <div v-if="step5">
          <div class="av-test d-flex justify-content-between">
            <div class="av-div1 d-flex">
              <span class="material-symbols-outlined"> rotate_right </span>
              <span class="mx-2 fw-bold">Lat long check</span>
            </div>
            <button class="av-test-btn d-flex" @click="updateCaseDetails()">
              <span class="material-symbols-outlined"> play_arrow </span>
              <div class="av-test-btn-text fs-bold">Test</div>
            </button>
          </div>
        </div>
          <div class="r-timer text-danger">05:34</div>
        </div> -->
    <!-- </div> -->
  </section>
</template>

<script>
import axios from "axios";
// import lamejs from 'lamejs';
import toWav from "audiobuffer-to-wav";
import eventBus from "../eventBus";
import Chat from "./chatBox.vue";
import { VueWebRTC } from "vue-webrtc";
// import {lightThreshold} from "../../threshhold.config";
export default {
  name: "audioVideo",
  props: {
    startStep: Number,
    room2Active: Boolean
  },
  data() {
    return {
      acceptanceCheck: {
        lightCheck: "",
        audioVideoCheck: "",
        backgroundCheck: "",
        latLongCheck: "",
      },
      audioWarning: '',
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      show: false,
      toastMessage: "",
      lightQuality: "",
      lightStatus: "",
      permissionsGranted: true,
      roomId: "",
      roomJoined: false,
      videoId: "",
      personalDetails: {
        name: "",
        lastname: "",
        dob: "",
      },
      url: "",
      lightWarning: "",
      permanentAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        pincode: "",
        city: "",
      },
      settings: {},
      latitude: null,
      longitude: null,
      audioContext: {},
      mediaSource: null,
      audioBuffer: null,
      videoElement: null,
      videoWarning: "",
      loader: false,
      sourceNode: null,
      enableChat: false,
      flexDivDisplay: "none!important",
    };
  },
  watch: {
    startStep(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);
      if (newValue >= 1) {
        if (newValue == 1 || newValue == "1") {
          this.step1 = true;
        }
        //  alert("opening camera");
        this.step = newValue;
        this.flexDivDisplay = "flex!important";
        //  this.toggleRoom2();
      }
    },
  },
  components: {
    "vue-webrtc": VueWebRTC,
    Chat,
  },


  toggleFlexDiv() {
    this.flexDivDisplay =
      this.flexDivDisplay === "flex!important"
        ? "none!important"
        : "flex!important"; // Toggle the display property
  },
  created() {
    this.getDataById();
    this.getData();
    this.liveAcceptanceCheck();
    // let step = this.$route?.query?.step ;
    this.roomId = this.$route?.query?.id;
    console.log("hello", this.roomId);

    // this[`step${step}`] = true;
    // if (step == 2) {
    //   this.acceptanceCheck.audioVideoCheck = "Good";
    // }
  },
  mounted() {
    document.title = "Live Acceptance Check";
     this.toggleRoom2();
    eventBus.$on("close", this.closePopUp);
  },
  methods: {
    async liveAcceptanceCheck() {

      const updateData = {
        status: "VKYC_Ongoing"
      };
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        console.log(response)
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    handleEvent() {
      this.enableChat = false;
    },
    closePopUp() {
      this.enableChat = false;
    },
    async navigateToStep(stepNumber) {

      // if(stepNumber == "3"){
      //   this.$emit('onLiveComplete', { message: 'Live Accepteance is complete', someother: 1 });
      // }
      alert(stepNumber)

      if (stepNumber == "2") {
        this.loader = true;
        this.analyzeLightQuality(document.getElementById(this.videoId));
        this.lightStatus =
          parseInt(this.lightQuality) > parseInt(this.settings.lightThreshold)
            ? "Good"
            : "Bad";
        this.lightWarning = this.lightStatus == "Bad" ? "Poor Lighting" : "";
        this.acceptanceCheck.lightCheck = this.lightStatus;
        if (this.lightStatus == "Bad") {
          this.loader = false;
          this.isLoading = false;
          setTimeout(() => {
            this.$toast.open({
              message: 'Please improve your background!',
              type: 'error',
              duration: 3000
            });
          }, 3000);
          this.acceptanceCheck = {
            lightCheck: this.lightStatus,
            audioVideoCheck: "",
            backgroundCheck: "",
            latLongCheck: "",
          };
        } else {
          this.isLoading = false;
          this.loader = false;
          this.acceptanceCheck = {
            lightCheck: "Good",
            audioVideoCheck: "",
            backgroundCheck: "",
            latLongCheck: "",
          };
        }

      } else if (stepNumber == "3") {
        this.loader = true;
        await this.cutAudio();

        if (this.audioWarning == "Poor audio quality") {
          this.loader = false;
          this.isLoading = false;
          setTimeout(() => {
            this.$toast.open({
              message: 'Poor audio quality',
              type: 'error',
              duration: 3000
            });
          }, 3000);
        } else {
            this.isLoading = false;
            this.loader = false;
            this.acceptanceCheck = {
            lightCheck: "Good",
            audioVideoCheck: "Good",
            backgroundCheck: "",
            latLongCheck: "",
          };
        }
        console.log("step3");
      } else if (stepNumber == "4") {
        await this.cutVideo();

        this.acceptanceCheck = {
          lightCheck: "Good",
          audioVideoCheck: "Good",
          backgroundCheck: "Good",
          latLongCheck: "",
        };
        this.loader = false;

        if (this.videoWarning) return;
        console.log("step4");
      } else if (stepNumber == "5") {
        console.log("step5");
        const latLongStatus = await this.latLongCheck();
        this.acceptanceCheck = {
          lightCheck: "Good",
          audioVideoCheck: "Good",
          backgroundCheck: "Good",
          latLongCheck: latLongStatus,
        };
        console.log("Lat-long check result:", latLongStatus);
      }

      this[`step${stepNumber - 1}`] = false;
      this[`step${stepNumber}`] = true;

      setTimeout(() => {

        this.navigateToIdentityCheckDone();

      }, 1000);
    },







    async navigateToIdentityCheckDone() {
      let updateData = {
        acceptanceCheck: this.acceptanceCheck,
      };
      // updateData.identityCheck.uploadedPanQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // updateData.identityCheck.livePhotographQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // updateData.identityCheck.liveCapturedPanQuality = this.isRandom()
      //   ? "Good"
      //   : "recheck";
      // (updateData.identityCheck.faceMatch = "Issue"),
      //   (updateData.identityCheck.customerLiveliness = "Issue"),
      //   (updateData.identityCheck.randomQuestionnaire = "Questions");

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        // this.navigateToIdentyCheck(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    toggleChatbox() {
      this.enableChat = !this.enableChat;
    },
    initializeAudioContext() {
      // Initialize the Web Audio API
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
    },
    async cutAudio() {
      // Get the video element
      const video = document.getElementById(this.videoId);

      // Create an audio context
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      if (this.sourceNode) {
        // Disconnect and clear the existing source node
        this.sourceNode.disconnect();
        this.sourceNode = null;
      }

      try {
        // Create a source node from the video
        this.sourceNode = audioContext.createMediaElementSource(video);
      } catch (error) {
        console.log(error);
      }

      // Create a gain node to control the volume
      const gainNode = audioContext.createGain();

      // Connect the source to the gain node
      this.sourceNode.connect(gainNode);

      // Connect the gain node to the destination (output)
      gainNode.connect(audioContext.destination);

      // Set the start and end time for the audio clip (in seconds)
      const startTime = 10; // example start time
      const endTime = 15; // example end time

      // Schedule the stop of the audio after the specified duration
      gainNode.gain.setValueAtTime(1, audioContext.currentTime);
      gainNode.gain.linearRampToValueAtTime(
        0,
        audioContext.currentTime + (endTime - startTime)
      );

      // Set the video source and start playing
      video.src = this.videoSource;
      video.currentTime = startTime;
      video.play();
      await this.uploadAudio();
    },
    async uploadAudio() {
      const startTime = 10; // example start time
      const endTime = 15; // example end time
      return new Promise((resolve) => {
        setTimeout(async () => {
          // Get the audio buffer from the audio context
          const sampleRate = this.audioContext.sampleRate;
          const duration = 10; // seconds
          const numChannels = 1; // mono

          const bufferSize = sampleRate * duration;
          const audioBuffer = this.audioContext.createBuffer(
            numChannels,
            bufferSize,
            sampleRate
          );

          for (let channel = 0; channel < numChannels; channel++) {
            const nowBuffering = audioBuffer.getChannelData(channel);
            nowBuffering.set(this.audioContext, channel);
          }

          this.audioBuffer = audioBuffer;
          await this.uploadToServer();
          this.loader = false;
          resolve();
        }, (endTime - startTime) * 1000);
      });
    },
    // async uploadToServer() {
    //   const wav = toWav(this.audioBuffer);
    //   const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });
    //   const formData = new FormData();
    //   formData.append("audio_file", blob, "audio.wav");

    //   console.log("hello resp", blob);

    //   // Iterate over FormData entries for debugging
    //   for (let pair of formData.entries()) {
    //     console.log("hello resp1", `${pair[0]}:`, pair[1]);
    //   }

    //   try {
    //     const response = await axios.post(
    //       "https://vcipocr-new.algofolks.com/audio_detection",
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data"
    //         }
    //       }
    //     );

    //     console.log("hello resp2", response);

    //     if (response.data.voice_quality_ratio < this.settings.voiceThreshold) {
    //       this.audioWarning = "Poor audio quality";
    //     } else {
    //       this.audioWarning = "";
    //     }
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //   }
    // },

    async uploadToServer() {
      try {
        // Load the audio file from the project using require
        const audioPath = require('@/assets/audio/audio.wav');
        
        // Fetch the audio file as a Blob
        const response = await fetch(audioPath);
        const audioBlob = await response.blob();
        
        const formData = new FormData();
        formData.append("audio_file", audioBlob, "audio.wav");

        console.log("Blob:", audioBlob);

        // Post to server
        const serverResponse = await axios.post(
          "https://vcipocr-new.algofolks.com/audio_detection",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );

        console.log("Server Response:", serverResponse);

        // Handle response
        if (serverResponse.data.voice_quality_ratio < this.settings.voiceThreshold) {
          this.audioWarning = "Poor audio quality";
        } else {
          this.audioWarning = "";
        }

      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },

    saveAudio() {
      if (this.audioBuffer) {
        const wav = toWav(this.audioBuffer);
        const blob = new Blob([new Uint8Array(wav)], { type: "audio/wav" });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "audio.wav";

        // Trigger a click on the link to start the download
        downloadLink.click();
      }
    },
    async cutVideo() {
      try {
        const videoElement = document.getElementById(this.videoId);

        if (!videoElement) {
          console.error("Video element not found.");
          return;
        }

        const canvas = document.createElement("canvas");
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        const frameData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        const backgroundQualityScore = this.analyzeBackgroundQuality(frameData);

        console.log("Background Quality Score:", backgroundQualityScore);

        this.videoWarning =
          backgroundQualityScore < this.settings.backgroundThreshold
            ? "Poor background quality"
            : "";

          this.loader = true;

          setTimeout(() => {
            if (this.videoWarning) {
              this.$toast.open({
                message: 'Please improve your background!',
                type: 'error',
                duration: 3000,
              });

                this.loader = false;

            } else {
              this.$toast.open({
                message: 'Background quality is good!',
                type: 'success',
                duration: 3000,
              });

              this.loader = false;
            }
          }, 3000);


      } catch (error) {
        console.error("Error analyzing video:", error);
        this.loader = false;
      }
    },

    analyzeBackgroundQuality(frameData) {
      let totalBrightness = 0;

      for (let i = 0; i < frameData.data.length; i += 4) {
        const r = frameData.data[i];     // Red channel
        const g = frameData.data[i + 1]; // Green channel
        const b = frameData.data[i + 2]; // Blue channel

        const brightness = (r + g + b) / 3;
        totalBrightness += brightness;
      }

      const avgBrightness = totalBrightness / (frameData.width * frameData.height);

      return avgBrightness;
    },

    async latLongCheck() {
      try {
        const position = await this.getCurrentPosition();

        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        console.log("User's Location: ", { latitude, longitude });

        const address = await this.getAddressFromCoordinates(latitude, longitude);

        if (!address) {
          console.error("Failed to retrieve address.");
          this.latLongWarning = "Unable to retrieve address.";
          await this.saveLatLongToDB(latitude, longitude, null);
          return "Bad";
        }

        console.log("Resolved Address:", address);

        if (address) {
          await this.saveLatLongToDB(latitude, longitude, address);
          return "Good";
        } else {
          await this.saveLatLongToDB(latitude, longitude, null);
          return "Bad";
        }

      } catch (error) {
        console.error("Error fetching location:", error);
        this.latLongWarning = "Unable to get location.";
        await this.saveLatLongToDB(null, null, null);
        return "Bad";
      }
    },

    async getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    },

    async getAddressFromCoordinates(latitude, longitude) {
      const apiKey = "AIzaSyCV34z3L6tSwjLGFFjhSBIIusXo3zn8RWs";
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
          return data.results[0].formatted_address;
        } else {
          console.error("Geocoding API response error:", data);
          return null;
        }
      } catch (error) {
        console.error("Error fetching geocoding API:", error);
        return null;
      }
    },

    async saveLatLongToDB(latitude, longitude, address) {
      const scriptElement = document.querySelector("script[api-url]");
      const apiUrl = scriptElement.getAttribute("api-url");

      const userId = this.getUserIdFromUrl();

      const saveApiUrl = `${apiUrl}/case/updateLocation/${userId}`;
      const payload = {
        latitude,
        longitude,
        address,
      };

      try {
        const response = await fetch(saveApiUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          console.log("Location data saved to DB:", await response.json());
        } else {
          console.error("Failed to save location data:", response.statusText);
        }
      } catch (error) {
        console.error("Error saving location data to DB:", error);
      }
    },

    getUserIdFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('id');
    },

    sendToServer(clippedVideo) {
      console.log(clippedVideo);
    },
    //     toggleRoom2() {

    //        alert("Camera  toggleRoom2 ");


    //       if (this.$parent.room2Active) {
    // alert("Camera  toggleRoom2 done");
    //       this.roomJoined = true;
    //       if (this.roomId) {
    //         try {
    //           if (this.hasJoined) {
    //             this.$refs.webrtc.leave();
    //             this.hasJoined = false;
    //           } else {
    //             // this.socket.emit("joinRoom", this.roomId);
    //             // setTimeout(() => (this.roomJoined = false), 1000);
    //             try {
    //               this.$refs.webrtc.join().then(() => {
    //                 this.userStream = this.$refs.webrtc?.videoList[0]?.stream;
    //                 this.analyzeLightQuality(document.getElementById(this.userStream.id))
    //                 this.videoId = this.userStream.id;
    //                 this.initializeAudioContext();
    //                 this.mediaRecorder = new MediaRecorder(this.userStream);
    //                 this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
    //                 this.mediaRecorder.onstop = () => this.onStop();
    //                 this.hasJoined = true;
    //               });
    //             } catch (error) {
    //               alert("Camera Not Found");
    //               return;
    //             }
    //           }
    //         } catch (e) {
    //           alert(e);
    //         }
    //       } else {
    //         console.log("room id not found");
    //       }
    //     }
    //     },


    toggleRoom2() {
      if (!this.$parent.room2Active) {
        this.$refs.webrtc.leave();
        this.hasJoined = false;
        return;
      }

      this.roomJoined = true;

      if (this.roomId) {
        try {
          if (this.hasJoined) {
            this.$refs.webrtc.leave();
            this.hasJoined = false;
          } else {
            this.checkPermissions().then((permissionsGranted) => {
              if (permissionsGranted) {
                this.permissionsGranted = true;
                localStorage.setItem("permissionsGranted", this.permissionsGranted);
                console.log("permissionsGranted0", this.permissionsGranted);

                try {
                  this.$refs.webrtc.join().then(() => {
                    const checkVideoList = () => {
                      this.displayStream = this.$refs.webrtc.videoList[0].stream;
                      const abc = document.getElementById(this.displayStream.id);
                        if (abc) {
                          abc.style.display = 'none';
                        } else {
                          console.error('Element with the given ID not found');
                        }
                      if (this.$refs.webrtc?.videoList[1]) {
                        this.userStream = this.$refs.webrtc.videoList[1].stream;
                        console.log("ddd", this.$refs);
                        localStorage.setItem('videoElement', this.userStream.id);
                        this.analyzeLightQuality(document.getElementById(this.userStream.id));
                        this.videoId = this.userStream.id;
                        this.initializeAudioContext();
                        this.mediaRecorder = new MediaRecorder(this.userStream);
                        console.log("print", this.userStream);
                        this.mediaRecorder.ondataavailable = (e) => this.pushData(e);
                        this.mediaRecorder.onstop = () => this.onStop();
                        this.hasJoined = true;
                      } else {
                        setTimeout(checkVideoList, 500);
                      }
                    };

                    checkVideoList();

                  });
                } catch (error) {
                  this.showPopup("Camera Not Found");
                  return;
                }
              } else {
                this.permissionsGranted = false;
                localStorage.setItem("permissionsGranted", this.permissionsGranted);
              }
            }).catch((error) => {
              this.permissionsGranted = false;
              console.log("permissionsGranted1", this.permissionsGranted);
              this.showPopup(error);
            });
          }
        } catch (e) {
          this.showPopup(e.message);
        }
      } else {
        console.log("room id not found");
      }
    },

    showPopup(message) {
      const popup = document.getElementById("permissionPopup");
      const popupMessage = document.getElementById("popupMessage");
      popupMessage.innerText = message;
      popup.style.display = "block";
    },
    closePermissionPopup() {
      const popup = document.getElementById("permissionPopup");
      popup.style.display = "none";
    },
    async checkPermissions() {
      try {
        // Check for camera and microphone permissions
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

        // Check for location permission
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            () => resolve(true),
            () => {
              this.showPopup("Location permission is required. Please enable it."); // Use custom popup
              reject("Location permission denied.");
            }
          );
        });
      } catch (error) {
        this.showPopup("Camera and microphone permissions are required. Please enable them."); // Use custom popup
        return false;
      }
    },


    async getData() {
      try {
        // this.loader = true;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.get(`${apiUrl}/setting/getSetting`);
        this.settings = response?.data?.settings[0];
        // console.log(response)
      } catch (error) {
        console.log(error);
      }
    },
    async startScreenCapture(stepNumber) {
      const videoElement = document.getElementById(this.videoId);
      const canvasElement = this.$refs.canvasElement;
      const capturedImageElement = this.$refs.captureArea;

      const canvasContext = canvasElement.getContext("2d");
      canvasElement.width = 300;
      canvasElement.height = 300;

      canvasContext.drawImage(videoElement, 0, 0, 300, 300, 0, 0, 300, 300);
      const imageDataURL = canvasElement.toDataURL("image/jpeg");

      capturedImageElement.src = imageDataURL;
      capturedImageElement.style.display = "block";
      this.show = true;

      let response = await this.uploadPan(imageDataURL);
      if (response == "error") return;
      this.navigateToStep(stepNumber);
    },
    analyzeLightQuality(videoElement) {
      if (!videoElement) {
        const videoElementFromStorage = localStorage.getItem('videoElement');
        if (videoElementFromStorage) {
            videoElement = JSON.parse(videoElementFromStorage);
        } else {
            console.error("Video element not found in localStorage.");
            return;
        }
    }
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = videoElement.height;
      const height = videoElement.height;

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(videoElement, 0, 0, width, height);

      const imageData = ctx.getImageData(0, 0, width, height);
      const pixelData = imageData.data;
      let totalBrightness = 0;

      for (let i = 0; i < pixelData.length; i += 4) {
        const r = pixelData[i];
        const g = pixelData[i + 1];
        const b = pixelData[i + 2];
        const brightness = (r + g + b) / 3;
        totalBrightness += brightness;
      }

      const averageBrightness = totalBrightness / (width * height);
      this.lightQuality = `${averageBrightness.toFixed(2)}`;
    },
    getCurrentLocation() {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject(new Error("Geolocation is not supported in this browser."));
        }
      });
    },
    async updateCaseDetails() {
      const position = await this.getCurrentLocation();

      const updateData = {
        acceptanceCheck: this.acceptanceCheck,
        latitude: position.latitude,
        longitude: position.longitude,
        status: "VKYC_Completed"
      };

      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.put(
          `${apiUrl}/case/update/${id}`,
          updateData
        );
        this.$refs.webrtc.leave();
        this.navigateToAcceptanceCheckDone(id);
        console.log("Update response:", response);
      } catch (error) {
        console.error("Update error:", error);
      }
    },
    async uploadPan(url) {
      const formData = new FormData();
      const blob = this.base64toBlob(url, "image/jpeg");

      formData.append("panImage", blob);
      formData.append("panNameRatio", "");
      const scriptElement = document.querySelector("script[python-api-url]");
      const pythonApiUrl = scriptElement.getAttribute("python-api-url");
      const response = await axios.post(`${pythonApiUrl}/panUpload`, formData);
      if (response?.data?.error) {
        this.toastMessage = "Invalid Pan";
        return "error";
      }
      console.log(response);
    },
    base64toBlob(base64String, contentType) {
      const dataURI = "data:image/jpeg;base64,";
      const base64DataUri = base64String.replace(dataURI, "");
      const byteCharacters = atob(base64DataUri);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    navigateToAcceptanceCheckDone(id) {
      // this.$router.push("/vcipIn?id=" + id);
      this.getData();
      this.$emit('onLiveComplete', { message: 'Live Accepteance is complete', someother: 1, id: id, });
    },

    async getDataById() {
      try {
        const id = this.$route.query.id;
        const scriptElement = document.querySelector("script[api-url]");
        const apiUrl = scriptElement.getAttribute("api-url");
        const response = await axios.post(`${apiUrl}/case/getById/${id}`);
        const data = response.data;
        console.log("API Response:", data);
        this.personalDetails.name = data.personalDetails.name;
        this.personalDetails.lastname = data.personalDetails.lastname;
        this.personalDetails.dob = data.personalDetails.dob;
        this.permanentAddress.addressLine1 = data.permanentAddress.addressLine1;
        this.permanentAddress.addressLine2 = data.permanentAddress.addressLine2;
        this.permanentAddress.addressLine3 = data.permanentAddress.addressLine3;
        this.permanentAddress.pincode = data.permanentAddress.pincode;
        this.permanentAddress.city = data.permanentAddress.city;
        this.url = data.otp;
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
  margin: 5px 0px;
}

.form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
}

.login-page {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 11px !important;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .for-camera {
  width: 93%;
  height: 50vh;
  position: absolute;
  margin-top: 3rem;
} */

.camera {
  width: 100%;
  height: 64vh;
  background-color: #39bb8f;
  padding: 2rem;
  border-radius: 8px;
  align-items: flex-end;
}

.av-test {
  width: 100%;
  background-color: white;
  height: 10vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e6;
}

.av-div1 {
  width: 160px;
}

.av-test-btn {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: #ee8d33;
  border: 2px solid #eb780d;
  color: white;
}

.i-h {
  color: grey;
}

@media screen and (min-width: 280px) and (max-width: 700px) {
  .form-section {
    /* width: 100%; */
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 0px;
    justify-content: center;
  }

  .login-header {
    margin-bottom: 1rem;
  }

  .for-camera {
    margin-top: -8rem;
    height: 70vh;
    width: -webkit-fill-available;
  }

  .camera {
    /* height: 50vh; */
    border-radius: 0px;
  }

  /* .av-test{
    font-size: 14px;
    height: 44px;
  } */
  .i {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .form-section {
    /* width: 100%; */
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    min-height: 100vh;
    padding: 0px;
    justify-content: center;
  }

  .login-header {
    margin-bottom: 1rem;
  }

  .for-camera {
    margin-top: -8rem;
    height: 59vh;
    width: -webkit-fill-available;
  }

  .camera {
    height: 70vh;
    border-radius: 0px;
  }

  .av-test {
    /* font-size: 18px; */
    height: 60px;
  }

  .i {
    padding: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .form-section {
    /* width: 100%; */
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    /* height:100vh; */
  }

  .login-header {
    /* margin-bottom: 1rem; */
    font-size: 18px;
    font-weight: 600;
  }

  /* .form-section {
  position: relative;
  padding: 40px 300px;
  background-color: #073991;
  min-height: 100vh;
} */

  .for-camera {
    height: 73vh;
  }

  .camera {
    height: 79vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .i {
    padding: 1rem;
    font-size: 18px;
  }

  .av-test {
    font-size: 18px;
    height: 50px;
  }
}

@media all and (orientation: landscape) and (min-width: 540px) and (max-width: 1024px) {
  .form-section {
    /* width: 100%; */
    padding: 0px 0px;
  }

  .login-page {
    border: none;
    border-radius: 0px;
    /* height:100vh; */
  }

  .login-header {
    /* margin-bottom: 1rem; */
    font-size: 18px;
    font-weight: 600;
  }

  .for-camera {
    height: 73vh;
  }

  .camera {
    height: 93vh;
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .i {
    padding: 1rem;
    font-size: 18px;
  }

  .av-test {
    font-size: 18px;
    height: 50px;
  }
}

.captureArea {
  border: 2px dotted white;
  z-index: 999;
  width: 350px;
  height: 350px;
  position: relative;
  left: -205px;
  top: 0px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 999;
  /* Ensure the overlay is on top */
}

.loader {
  border: 1px solid #f3f3f3;
  border-top: 1px solid #3498db;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chatIcon {
  position: fixed;
  bottom: 30px;
  right: 30px;

}

.closeIcon {
  position: absolute;
  /* top: -3px; */
  top: -382px;
  right: 8px;
  z-index: 100;
  font-size: 35px;
  color: lightgrey;
  cursor: pointer;

}

.video-list[data-v-41044b41] {
  background: #d8d8d8;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  /* padding: 20px; */
  border-radius: 10px;
  z-index: 100;
  /* width: 50vh; */
  background-color: rgb(209, 220, 252);
  /* border: 1px solid #ccc; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  /* height: 500px; */
  /* overflow-y: auto; */
}

.for-camera[data-v-3fa77605] {
  width: 103%;
  height: auto !important;
  position: unset !important;
  ;
  margin-top: 3rem;
}

.av-test[data-v-3fa77605] {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  height: 5vh;
  align-items: center;
  padding-inline: 1rem;
  border-bottom: 2px solid #e6e6e600;
  gap: 309px;
}

#call-canvas {
  width: 800px;
  object-fit: cover;
}


.recordComp {
  background-color: #f4f4f4;
  border: 1px solid rgb(192, 192, 192);
  padding: 11px !important;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
}

.video-list[data-v-41044b41][data-v-3fa77605] {
  background: #d8d8d8;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: revert !important;
}


.fa-comment-alt:before,
.fa-message:before {
  content: "\f27a";
  display: none;
}

</style>
